export const FETCHING = '_FETCHING'
export const ERROR = '_ERROR'
export const FETCHED = '_FETCHED'
export const RESET = '_RESET'

// USERS
export const USERS_INITIAL = "USERS_INITIAL"
export const API_GET_USERS = "API_GET_USERS"
export const API_GET_USER = "API_GET_USER"
export const API_POST_USERS = "API_POST_USERS"
export const RESET_USER = "RESET_USER"
export const INFO_USER = "INFO_USER"
export const API_GET_INSURER_USUARIO = "API_GET_INSURER_USUARIO"
export const API_PUT_USER_COMMISSIONS = "API_PUT_USER_COMMISSIONS"
export const API_USERS_FILTERS_COUNTRIES = "API_USERS_FILTERS_COUNTRIES"
export const API_USERS_FILTERS_ROLES = "API_USERS_FILTERS_ROLES"
export const API_USERS_FILTERS_CITIES = "API_USERS_FILTERS_CITIES"
export const UPDATE_INSUREDS_USER = "UPDATE_INSUREDS_USER"
export const SELECT_USER = "SELECT_USER"
export const API_PUT_USER = "API_PUT_USER"
export const API_PUT_USER_PASSWORD = "API_PUT_USER_PASSWORD"
export const RESET_PASSWORD_FETCH = "RESET_PASSWORD_FETCH"
export const API_DELETE_USER = "API_DELETE_USER"
export const API_EXPORT_USER = "API_EXPORT_USER"
export const USER_SEARCH = "USER_SEARCH"
export const API_GET_PROGRAMS_COMISION = "API_GET_PROGRAMS_COMISION";
export const CREATE_USER_CHANGE_EMPRESA = "CREATE_USER_CHANGE_EMPRESA"
export const UPDATE_SELECT_INSURERS = "UPDATE_SELECT_INSURERS"
export const USERS_SELECT_ID = "USERS_SELECT_ID"
export const RESET_UPDATE_COMMISSIONS = "RESET_UPDATE_COMMISSIONS"
export const SET_INSURER_USUARIO = "SET_INSURER_USUARIO"
export const UPDATE_CREATE_USER_FECHED = "UPDATE_CREATE_USER_FECHED"
export const API_GET_ADMINS = "API_GET_ADMINS"
export const API_GET_SMART_COACH = "API_GET_SMART_COACH"
export const API_GET_EMPRESA_USUARIO = "API_GET_EMPRESA_USUARIO"
export const SET_SECTOR_USUARIO = "SET_SECTOR_USUARIO"
export const API_POST_UPLOAD_USER = "API_POST_UPLOAD_USER"
export const API_GET_SECTORS_COACH = "API_GET_SECTORS_COACH"
export const API_PUT_USER_COMMISSIONS_SECTORS = "API_PUT_USER_COMMISSIONS_SECTORS"
export const API_PUT_USER_SECTORS = "API_PUT_USER_SECTORS"
