
import * as ActionsTypes from "./types";

const ini = {
  quotation_salud:{
    agentLink:'',
    link:'',
    idPlanilla: '',
    status:null,
    type: "",
    place:{
      country:'',
      city:''
    },
    paymentType: "",
    dependents: [],
    billing:{
      id: false,
      name: "",
      lastName: "",
      document: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      country: ""
    },
    contact:{
      name:"",
      lastName:"",
      phone:"",
      email:""
    },
    prospect:{
      name: "",
      lastName: "",
      birthDate:"",
      email: "",
      gender:"",
      specialCoverage:""
    },
    pay:{
      name:"",
      number:["","","",""],
      expiration:["",""],
      ccv:""
    },
    card_validation: {
      fetching: false,
      fetched: false,
      result: null,
    },
    insureds:[],
    answers:[],
    done_answers: false,
    done_pay: false,
    planilla: null,
  },
  error:{
    cotizador:{
      type: false,
      paymentType: false,
      country: false,
      city:false,
      contact:{
        firstName: false,
        lastName: false,
        email:false,
        phone:false
      },
      prospect:{
        name: false,
        lastName: false,
        email:false,
        birthDate: false,
        gender: false
      },
      insureds:[]
    }
  },
  pagination: {
    step: 10,
    page: 0,
    pages: 0,
    total: 0,
  },
  fetched: null,
  fetching: null,
  specialCoverages: [],
  document_types: [],
  payment_types: [],
  program_types:[],
  programs_salud: [],
  filters_salud: [],
  client_token: null,
  get_quotation_info_fetching: null,
  get_quotation_info_fetched: null,
  quotation_info: null,
  polizas_salud_selected:[],
  relationship_types: [],
  statuses: [],
  insurers: [],
  ventas: [],
  ventas_filter: [],
  extra_params: null,
  id_quotation: null,
  search_param_quotes: "",
  search_param_quotes_loading: false,
  programs_salud_fetching: null,
  filters_salud_fetching: null,
  filters_salud_fetched: null,
  programs_salud_fetched: null,
  put_options_salud_fetching: null,
  put_options_salud_fetched: null,
  contact_update_fetched: null,
  salud_billing_update_fetched: null,
  salud_insureds_put_fetched: null,
  salud_insureds_put_all_fetched: null,
  salud_planilla_fetching: null,
  salud_planilla_fetched: null,
  salud_planilla_error: null,
  salud_planilla_get_info_fetching: null,
  salud_planilla_get_info_fetched: null,
  salud_planilla_put_approved_fetched: null,
  salud_planilla_put_approved_fetching: null,
  salud_planilla_put_approved_error: null,
  salud_insureds_put_all_error: null,
  salud_selected_quotation_option_health: {
    data: null,
    feching: false,
    feched: false,
    option: null,
    change_health_state_feching: false,
    change_health_state_feched: false,
    change_health_state_result: null,
    change_health_state_error: null,

    save_health_state_feching: false,
    save_health_state_feched: false,
    save_health_state_result: null,
    save_health_state_error: null,
  },
  salud_landing_files: {
    quote_feching: false,
    quote_feched: false,
    quote_result: null,
    quote_error: null,

    upload_feching: false,
    upload_feched: false,
    upload_result: null,
    upload_error: null,
  },
  salud_options_statuses: [],
  salud_options_statuse_feching: false,
  salud_options_statuse_feched: false,
  emit_salud_fetching: false,
  emit_salud_fetched: false,
  emit_salud_error: false,
  put_principal_option_fetching: false,
  put_principal_option_fetched: false,
  put_principal_option_error: false,
  contact_update_fetching: false,
  contact_update_fetched: false,
  contact_update_result: null,
  programs_salud_error:false,
  programs_salud_error_message: '',
  update_contact_quotation: false,
  sale_salud:{},
  get_sale_fetched:false,
  get_sale_fetching:false,
  get_sale_error:false,
  programs:[],
  programsValues:{},
  programsValuesUsuario:{}, 
  aditional_filter:false
};
const temp = JSON.parse(JSON.stringify(ini));

const initial_state = ini;
let data;
export default (state = ini, action) => {
  switch (action.type) {
    case ActionsTypes.SALUD_RESET_QUOTATION:
      return{
        ...initial_state
      }
    case ActionsTypes.SET_PROGRAM_VALUE_SALUD:
      return {
        ...state,
        programsValues:{
          ...action.payload
        }
      }
    case ActionsTypes.SET_PROGRAM_VALUE_SALUD_USUARIO:
      return {
        ...state,
        programsValuesUsuario:{
          ...action.payload
        }
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_SALUD + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched_insurers_salud: false,
        fetching_insurers_salud: true,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_SALUD + ActionsTypes.FETCHED:
      const dataValues = action.payload.data;
      let programsValues = {}
      if(dataValues){
        for(let i = 0; i < dataValues.length; i++) {
          const dataValue = dataValues[i];
          for(let j = 0; j < dataValue.programs.length; j++) {
            const program = dataValue.programs[j];
            if(program.active){
              programsValues[program.id] = program.percentage
            }
          }
        }
      }
      return {
        ...state,
        programsValues,
        fetched_insurers_salud: true,
        fetching_insurers_salud: false,
      }

    case ActionsTypes.API_GET_EDIT_INSURERS_USUARIO_SALUD + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched_insurers_salud: false,
        fetching_insurers_salud: true,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_USUARIO_SALUD + ActionsTypes.FETCHED:
      const dataValuesUsuario = action.payload.data;
      let dataNewValuesUsuario = {}
      if(dataValuesUsuario){
        for(let i = 0; i < dataValuesUsuario.length; i++) {
          const dataValue = dataValuesUsuario[i];
          for(let j = 0; j < dataValue.programs.length; j++) {
            const program = dataValue.programs[j];
            if(program.active){
              dataNewValuesUsuario[program.id] = program.percentage
            }
          }
        }
      }
      return {
        ...state,
        programsValuesUsuario: dataNewValuesUsuario,
        fetched_insurers_salud: true,
        fetching_insurers_salud: false,
      }

    case ActionsTypes.API_GET_COACH_USUARIO_SALUD + ActionsTypes.FETCHING:
      return {
        ...state,
        get_programs_fetching: true,
        get_programs_fetched: false,
        get_programs_error: false,
      }
    case ActionsTypes.API_GET_COACH_USUARIO_SALUD + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: true,
          get_programs_error: false,
          programs: action.payload.data,
        }
      }else{
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: false,
          get_programs_error: true,
          programs: [],
        }
      }

    case ActionsTypes.SALUD_UPDATE_QUOTATION:
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          [action.payload.label]:action.payload.value
        },
        polizas_celulas_selected: []
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_CONTACT:
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          contact:{
            ...state.quotation_salud.contact,
            [action.payload.label]:action.payload.value
          }
        }
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_CONTACT_API + ActionsTypes.FETCHING:
      return {
        ...state,
        contact_update_fetching: true,
        contact_update_fetched: false,
        contact_update_result: null,
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_CONTACT_API + ActionsTypes.FETCHED:
      return {
        ...state,
        contact_update_fetched: true,
        contact_update_fetching: false,
        contact_update_result: action.payload.data,
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_CONTACT_ALL:
      return {
        ...state,
        quotation_salud:{
          ...state.quotation_salud,
          contact:action.payload
        }
      }
    case ActionsTypes.SALUD_RESET_FETCH_UPDATES:
      return {
        ...state,
        contact_update_fetched:false,
        salud_billing_update_fetched: false,
        salud_insureds_put_fetched: false,
        salud_planilla_get_info_fetching: false,
        salud_planilla_get_info_fetched: false,
        salud_planilla_fetching: false,
        salud_planilla_fetched: false,
        put_principal_option_fetching: false,
        put_principal_option_fetched: false,
        put_principal_option_error: false,
        salud_planilla_put_approved_fetched: false,
      }
    case ActionsTypes.API_GET_RELATIONSHIPS_TYPES_SALUD + ActionsTypes.FETCHED:
      return {
        ...state,
        relationship_types: action.payload.data,
      }
    case ActionsTypes.SALUD_RESET_PLANILLA_ERROR:
      return {
        ...state,
        salud_planilla_error: false
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_PROSPECT:
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          prospect:{
            ...state.quotation_salud.prospect,
            [action.payload.label]:action.payload.value
          }
        }
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_PLACE:
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          place:{
            ...state.quotation_salud.place,
            [action.payload.label]:action.payload.value
          }
        }
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_ADD_DEPENT:
      let dependents = state.quotation_salud.dependents;
      if(dependents){
        dependents.push({
          relationship:action.payload.type,
          name:"",
          lastName:"",
          gender:action.payload.gender,
          birthDate:"",
          specialCoverage:""
        })
      }else{
        dependents = [{
          relationship:action.payload,
          name:"",
          lastName:"",
          gender:"",
          birthDate:"",
          specialCoverage:""
        }]
      }
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          dependents
        }
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_SPLICE_DEPENT:
      dependents = state.quotation_salud.dependents;
      dependents.splice(action.payload,1)
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          dependents
        }
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_DEPENT:
      dependents = state.quotation_salud.dependents;
      dependents[action.payload.index][action.payload.label] = action.payload.value
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          dependents
        }
      }
    case ActionsTypes.SALUD_API_POST_COTIZACIONES + ActionsTypes.FETCHING:
      return {
        ...state,
        programs_salud_fetching: true,
        programs_salud_fetched: false,
        programs_salud_error:false,
        changeEmail:false,
        programs_salud_error_message:''
      }
    case ActionsTypes.UPDATE_PROGRAMS:
      return {
        ...state,
        polizas_salud_selected: [action.payload]
      }
    case ActionsTypes.REMOVE_ALL_PROGRAMS_SELECTED:
      return {
        ...state,
        polizas_salud_selected:[]
      }
    case ActionsTypes.SALUD_API_POST_COTIZACIONES + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        let aux_filters = action.payload.data.filters;
        let aux_filters_array = [];
        for (const filter in aux_filters) {
          aux_filters_array.push( { code:filter ,aux:aux_filters[filter], type:"range"});
        }
        return {
          ...state,
          programs_salud: action.payload.data.programs,
          filters_salud: aux_filters_array,
          polizas_salud_selected: [],
          id_quotation: action.payload.data.quote,
          programs_salud_fetching: false,
          programs_salud_fetched: true,
          programs_salud_error:false,
          programs_salud_error_message:'',
          changeEmail: false,
        }
      }else{
        if(action.payload.data.changeEmail){
          return {
            ...state,
            changeEmail: true,
            programs_salud: [],
            polizas_salud_selected: [],
            programs_salud_fetching: false,
            programs_salud_fetched: false,
            programs_salud_error:false,
          }
        }else{
          let contact = action.payload.data.contact;
          return {
            ...state,
            programs_salud: [],
            changeEmail: false,
            polizas_salud_selected: [],
            id_quotation: action.payload.data.quote,
            programs_salud_fetching: false,
            programs_salud_fetched: false,
            programs_salud_error:true,
            programs_salud_error_message:action.payload.data.message,
            update_contact_quotation: !!contact?    contact.phone?"phone":"email" : false
          }
        }
      }
    case ActionsTypes.SALUD_API_GET_FILTER_BOX  + ActionsTypes.FETCHING:
      return{
        ...state,
        filters_salud_fetching: true,
        filters_salud_fetched: false
      }
    case ActionsTypes.API_POST_SEND_QUOTE + ActionsTypes.FETCHING:
      return {
        ...state,
        send_fetched: false,
        send_fetching: true,
        send_data: null
      }
    case ActionsTypes.API_POST_SEND_QUOTE + ActionsTypes.FETCHED:
      return {
        ...state,
        send_fetched: true,
        send_fetching: false,
        send_data: action.payload.data
      }
    case ActionsTypes.RESET_SEND:
      return {
        ...state,
        send_fetched: null,
        send_fetching: null,
        send_data: null,
      }
    case ActionsTypes.RESET_UPDATE_CONTACT_QUOTATION:
      return {
        ...state,
        update_contact_quotation:false
      }
    case ActionsTypes.SALUD_API_GET_FILTER_BOX  + ActionsTypes.FETCHED:
      let payloadData = action.payload.data;

      // set default values for companies filter
      const companiesFilterIndex = payloadData.findIndex((filter) => filter.code === 'companies');
      if (companiesFilterIndex !== -1) {
        payloadData[companiesFilterIndex].aux.default = payloadData[companiesFilterIndex].aux.items.map(
          (company) => company.defaultValue === 1 ? company.id : false
        );
      }


      // set default values for options filter
      const optionsFilterIndex = payloadData.findIndex(filter => filter.code === 'options');
      if (optionsFilterIndex !== -1) {
        payloadData[optionsFilterIndex].aux.default = {};
        for(let option of payloadData[optionsFilterIndex].aux.options) {
          payloadData[optionsFilterIndex].aux.default[option.key] = option.defaultValue;
        }
      }


      let filters = payloadData;
      let new_filters_salud = state.filters_salud;
      for(let i = 0; i < filters.length; i++){
        const fil = filters[i];
        const index = new_filters_salud.findIndex((fi)=>fi.code === fil.code)
        if(index !== -1){
          if(fil.code !== 'coverage' && fil.code !== 'deductible' && fil.code !== 'price'){
            new_filters_salud[index] = fil
          }
        }else{
          if(fil.code !== 'coverage' && fil.code !== 'deductible' && fil.code !== 'price'){
            new_filters_salud.push(fil)
          }
        }
      }

      //console.log("filters_it",{filters,new_filters_salud})
      if(
        new_filters_salud && 
        new_filters_salud.length > 1 && 
        (
          new_filters_salud[new_filters_salud.length - 2].code === 'coverage' ||
          new_filters_salud[new_filters_salud.length - 2].code === 'deductible' ||
          new_filters_salud[new_filters_salud.length - 2].code === 'price'
        )
      ){
        new_filters_salud.reverse()
      }
      
      return {
        ...state,
        filters_salud: new_filters_salud,
        filters_salud_fetching: false,
        filters_salud_fetched: true
      }
    case ActionsTypes.RESET_FETCH_CONTACT:
      return {
        ...state,
        contact_update_fetched: null,
        salud_billing_update_fetched: null,
        salud_insureds_put_fetched: null,
        salud_insureds_put_all_fetched: null,
        salud_insureds_put_all_error: null,
        salud_planilla_fetching: null,
        salud_planilla_fetched: null,
        salud_planilla_error: null,
        salud_billing_update_result:null
      }
    case ActionsTypes.PUT_SALUD_ALL_INSUREDS:
      if(!!action.payload){
        return {
          ...state,
          salud_insureds_put_all_fetched: true,
          salud_insureds_put_all_error: false,
        }
      }else{
        return {
          ...state,
          salud_insureds_put_all_fetched: false,
          salud_insureds_put_all_error: true,
        }
      }
    case ActionsTypes.SALUD_API_UPDATE_FILTER_BOX_UPDATE:
      let {label, value} = action.payload;
      let filters_salud = state.filters_salud;
      let index = filters_salud.findIndex((filter)=>filter.code === label)
      if(index !== -1){
        if (label === "coverage" || label === "price" || label === "deductible"){
          filters_salud[index].aux = {
            min: value[0],
            max: value[1]
          }
        } else if (label === "companies") {
          filters_salud[index].aux.default = value;
        } else if (label === "options") {
          //filters_salud[index].aux.default[value] = filters_salud[index].aux.default[value] === 1 ? 0 : 1;
          // do nothing
        } else if (label === "plan") {
            filters_salud[index].aux.default = value;

        }
      }else if (label === "plan") {
        filters_salud.push({code: "plan", aux:{default:value}})
      }

      return {
        ...state,
        filters_salud,
        programs_salud_fetching: true,
        programs_salud_fetched: false
      }
    case ActionsTypes.SALUD_API_UPDATE_FILTER_BOX  + ActionsTypes.FETCHING:
      let aditional = action.aditional
      if(aditional){
        return {
          ...state,
          aditional_filter:false
        }
      }else{
        return {
          ...state,
        }
      }
      
    case ActionsTypes.SALUD_API_UPDATE_FILTER_BOX  + ActionsTypes.FETCHED:
      try {
        filters = action.payload.data.filters;
        aditional = action.aditional
        new_filters_salud = state.filters_salud;
        if(aditional){
          let {coverage, deductible, price} = filters
          for(let i = 0; i < new_filters_salud.length; i++){
              const fil = new_filters_salud[i];
           
              if(fil.code == 'coverage' && coverage){
                new_filters_salud[i].aux = coverage
              }else if(fil.code == 'deductible' && deductible){
                new_filters_salud[i].aux = deductible
              }else if(fil.code == 'price' && price){
                new_filters_salud[i].aux = price
              }
          }
          //console.log("filters", {aditional, new_filters_salud})
          return {
            ...state,
            programs_salud: action.payload.data.programs,
            filters: new_filters_salud,
            programs_salud_fetching: false,
            programs_salud_fetched: true,
            aditional_filter:true
          }
        }else{
          return {
            ...state,
            programs_salud: action.payload.data.programs,
            programs_salud_fetching: false,
            programs_salud_fetched: true
          }
        }
      } catch (error) {
        return {
          ...state,
          programs_salud: action.payload.data.programs,
          programs_salud_fetching: false,
          programs_salud_fetched: true
        }
      }
    case ActionsTypes.SALUD_API_DELETE_DOCUMENT + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          save_health_state_feching: true,
          save_health_state_feched: false,
          save_health_state_error: null,
        },
      }
    case ActionsTypes.SALUD_API_DELETE_DOCUMENT + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          save_health_state_feching: false,
          save_health_state_feched: true,
          save_health_state_error: null,
        },
      }
    case ActionsTypes.SALUD_API_DELETE_DOCUMENT +  ActionsTypes.ERROR:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          save_health_state_feching: false,
          save_health_state_feched: false,
          save_health_state_error: action.payload.data,
        },
      }
    case ActionsTypes.UPDATE_QUOTATION_SALUD_PAY + ActionsTypes.FETCHING:
      return {
        ...state,
        pay_salud_fetching: true,
        pay_salud_fetched: false,
        pay_salud_error: false,
        pay_salud_msg: ""
      }
    case ActionsTypes.UPDATE_QUOTATION_SALUD_PAY + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          pay_salud_fetching: false,
          pay_salud_fetched: true,
          pay_salud_error: false,
          pay_salud_msg: ""
        }
      }else{
        return {
          ...state,
          pay_salud_fetching: false,
          pay_salud_fetched: false,
          pay_salud_error: true,
          pay_salud_msg: action.payload.data.message
        }
      }
    case ActionsTypes.SALUD_GET_QUOTATION_BILLING + ActionsTypes.FETCHED:
      return {
        ...state,
        quotation_salud:{
          ...state.quotation_salud,
          billing:action.payload.data[0]
        }
      }
    case ActionsTypes.API_GET_SPECIAL_COVERAGE + ActionsTypes.FETCHED:
      return {
        ...state,
        specialCoverages: action.payload.data
      }
    case ActionsTypes.SALUD_POST_CREATE_PLANILLA + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_planilla_fetched: false,
        salud_planilla_fetching: true
      }
    case ActionsTypes.SALUD_POST_CREATE_PLANILLA + ActionsTypes.ERROR:
      return {
        ...state,
        salud_planilla_fetching: false,
        salud_planilla_fetched: false,
        salud_planilla_error: true
      }
    case ActionsTypes.SALUD_POST_CREATE_PLANILLA + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          salud_planilla_fetching: false,
          salud_planilla_fetched: true,
          salud_planilla_error: false
        }
      }else{
        return {
          ...state,
          salud_planilla_fetching: false,
          salud_planilla_fetched: false,
          salud_planilla_error: true
        }
      }
    case ActionsTypes.EXTRA_PARAMS:
      return {
        ...state,
        extra_params: action.payload,
      }
    case ActionsTypes.RESET_EXTRA_PARAMS:
      return {
        ...state,
        extra_params: null,
      }
    case ActionsTypes.SALUD_UPDATE_QUOTATION_BILLING_ALL:
      return {
        ...state,
        quotation_salud:{
          ...state.quotation_salud,
          billing:action.payload
        }
      }
    case ActionsTypes.API_GET_QUOTE_STATUSES_SALUD + ActionsTypes.FETCHING:
      return {
        ...state,
        statuses: [],
        statuses_feching: true,
        statuses_feched: false,
      }
    case ActionsTypes.API_GET_QUOTE_STATUSES_SALUD + ActionsTypes.FETCHED:

      let items = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.name
        };
      })
      items.unshift({
        id: null,
        text: "Estado de la cotizacion"
      });
      return {
        ...state,
        statuses: items,
        statuses_feching: false,
        statuses_feched: true,
      }
    case ActionsTypes.PUT_QUOTATION_SALUD_OPTIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        put_options_salud_fetching: false,
        put_options_salud_fetched: true
      }
    case ActionsTypes.FILTER_COTIZACION_SALUD:
      return {
        ...state,
        cotizaciones_filter: action.payload
      }
    case ActionsTypes.FILTER_VENTA_SALUD:
      return {
        ...state,
        ventas_filter: action.payload
      }
    case ActionsTypes.SET_CLIENT_TOKEN:
      return {
        ...state,
        client_token: action.payload,
      }
    case ActionsTypes.GET_QUOTATION_INFO + ActionsTypes.FETCHING:
      return {
        ...state,
        get_quotation_info_fetching: true,
        get_quotation_info_fetched: false,
      }
    case ActionsTypes.GET_QUOTATION_INFO + ActionsTypes.FETCHED:
      return {
        ...state,
        get_quotation_info_fetching: false,
        get_quotation_info_fetched: true,
        quotation_info: action.payload.data.quote,
      }
    case ActionsTypes.UPDATE_QUOTE_ID:
      return {
        ...state,
        id_quotation: action.payload
      }
    case ActionsTypes.GET_QUOTATION_BY_ID + ActionsTypes.FETCHING:
      return{
        ...state,
        get_cotizacion_fetched:false
      }
    case ActionsTypes.GET_QUOTATION_BY_ID + ActionsTypes.FETCHED:
      if(!!action.payload.data.quote){
        return {
          ...state,
          quotation_salud:{
            ...state.quotation_salud,
            ...action.payload.data.quote,
            insureds:state.quotation_salud.insureds
          },
          get_cotizacion_fetched: true
        }
      }else{
        return {
          ...state,
          get_cotizacion_fetched:true
        }
      }
    case ActionsTypes.GET_QUOTATION_SALE_BY_ID + ActionsTypes.FETCHING:
      return{
        ...state,
        get_sale_fetched:false,
        get_sale_fetching:true,
        get_sale_error:false
      }
    case ActionsTypes.GET_QUOTATION_SALE_BY_ID + ActionsTypes.FETCHED:
      if(!!action.payload.data.quote){
        return {
          ...state,
          sale_salud:{
            ...action.payload.data.quote,
          },
          get_sale_fetched:true,
          get_sale_fetching:false,
          get_sale_error:false
        }
      }else{
        return {
          ...state,
          get_sale_fetched:false,
          get_sale_fetching:false,
          get_sale_error:true
        }
      }
    case ActionsTypes.GET_QUOTATION_SALE_BY_ID + ActionsTypes.ERROR:
        return {
          ...state,
          sale_salud:{
            ...action.payload.data.quote,
          },
          get_sale_fetched:false,
          get_sale_fetching:false,
          get_sale_error:true
        }
    case ActionsTypes.POST_GET_SALUD_QUOTATION_OPTIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        post_get_options_salud_fetching: false,
        post_get_options_salud_fetched: true,
        polizas_salud_selected: action.payload.data.programs
      }
    case ActionsTypes.RESET_PAGINATION:
      return {
        ...state,
        search_param_sale: "",
        search_param_quotes: "",
        page_param_sale:  0,
        page_param_quotes: 0
      }

    case ActionsTypes.API_GET_PROGRAMS_SALUD + ActionsTypes.FETCHING:
      return {
        ...state,
        get_programs_fetching: true,
        get_programs_fetched: false,
        get_programs_error: false,
        programs: [],
      }

    case ActionsTypes.API_GET_PROGRAMS_SALUD + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.success){
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: true,
          get_programs_error: false,
          programs: action.payload.data.programs,
        }
      }else{
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: false,
          get_programs_error: true,
          programs: [],
        }
      }
    case ActionsTypes.SEARCH_COTIZACION_SALUD:
      return {
        ...state,
        search_param_quotes: action.payload.search,
        page_param_quotes:  action.payload.page,
        search_param_quotes_loading: true,
      }
    case ActionsTypes.SEARCH_SALE_SALUD:
      return {
        ...state,
        search_param_sale: action.payload.search,
        page_param_sale:  action.payload.page,
        search_param_sale_loading: true,
      }
    case ActionsTypes.API_GET_VENTAS_SALUD + ActionsTypes.FETCHED:
      return {
        ...state,
        ventas: action.payload.data.sales,
        ventas_filter: action.payload.data.sales,
        pagination: action.payload.data.pagination,
        fetched: true,
        fetching: false
      };
    case ActionsTypes.API_GET_VENTAS_SALUD + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched: false,
        fetching: true,
        search_param_sale_loading: false,
      };
    case ActionsTypes.SALUD_UPDATE_QUOTATION_BILLING_API + ActionsTypes.FETCHED:
      if(!!action.payload.data.success){
        return {
          ...state,
          salud_billing_update_fetched: true,
          salud_billing_update_fetching: false,
          salud_billing_update_result: action.payload.data,
          salud_billing_update_error: false
        }
      }else{
        return {
          ...state,
          salud_billing_update_fetched: false,
          salud_billing_update_fetching: false,
          salud_billing_update_result: action.payload.data,
          salud_billing_update_error:true
        }
      }
    case ActionsTypes.SALUD_PUT_QUOTATION_BILLING_API + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_billing_update_fetched: false,
        salud_billing_update_fetching: true,
        salud_billing_update_result: null,
      }
    case ActionsTypes.SALUD_PUT_QUOTATION_BILLING_API + ActionsTypes.FETCHED:
      if(!!action.payload.data.success){
        return {
          ...state,
          salud_billing_update_fetched: true,
          salud_billing_update_fetching: false,
          salud_billing_update_result: action.payload.data,
          salud_billing_update_error: false
        }
      }else{
        return {
          ...state,
          salud_billing_update_fetched: false,
          salud_billing_update_fetching: false,
          salud_billing_update_result: action.payload.data,
          salud_billing_update_error:true
        }
      }
    case ActionsTypes.GENERAL_POST_VALIDATE_CREDIT_CARD + ActionsTypes.FETCHING:
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          card_validation: {
            fetched: false,
            fetching: true,
            result: null,
            error:false
          },
        }
      }
    case ActionsTypes.GENERAL_POST_VALIDATE_CREDIT_CARD + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          quotation_salud: {
            ...state.quotation_salud,
            card_validation: {
              fetched: true,
              fetching: false,
              error:false,
              result: action.payload.data,
            },
          }
        }
      }else{
        return {
          ...state,
          quotation_salud: {
            ...state.quotation_salud,
            card_validation: {
              fetched: false,
              fetching: false,
              error:true,
              result: null,
            },
          }
        }
      }
    case ActionsTypes.RESET_GENERAL_POST_VALIDATE_CREDIT_CARD:
      return {
        ...state,
        quotation_salud: {
          ...state.quotation_salud,
          card_validation: {
            fetched: false,
            fetching: false,
            error:false,
            result: null,
          },
        }
      }
    case ActionsTypes.SALUD_GET_QUOTATION_INSUREDS + ActionsTypes.FETCHED:
      return {
        ...state,
        insureds_get_fetched:true,
        quotation_salud:{
          ...state.quotation_salud,
          insureds:action.payload.data
        }
      }
    case ActionsTypes.SALUD_PUT_QUOTATION_INSURED_API + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_insureds_put_fetched:true,
        /*quotation_salud:{
            ...state.quotation_salud,
            legalGuardian:action.payload.data[0]
        }*/
      }
    case ActionsTypes.POST_EMIT_SALUD_QUOTATION + ActionsTypes.FETCHING:
      return {
        ...state,
        emit_salud_fetching: true,
        emit_salud_fetched: false,
        emit_salud_error: false,
      }
    case ActionsTypes.POST_EMIT_SALUD_QUOTATION + ActionsTypes.FETCHED:
      if (action.payload.data.success) {
        return {
          ...state,
          emit_salud_fetching: false,
          emit_salud_fetched: true,
          emit_salud_error:false,
        }
      } else {
        return {
          ...state,
          emit_salud_fetching: false,
          emit_salud_fetched: false,
          emit_salud_error: true
        }
      }
    case ActionsTypes.API_GET_COTIZACIONES_SALUD + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched: false,
        fetching: true,
        search_param_quotes_loading: false,
      };
    case ActionsTypes.API_GET_COTIZACIONES_SALUD + ActionsTypes.FETCHED:
      return {
        ...state,
        ventas: action.payload.data.sales,
        ventas_filter: action.payload.data.sales,
        cotizaciones: action.payload.data.quotes,
        cotizaciones_filter: action.payload.data.quotes,
        pagination: action.payload.data.pagination,
        fetched: true,
        fetching: false
      };
    case ActionsTypes.SALUD_ADD_REMOVE_PROGRAM:
      let {polizas_salud_selected} = state;
      if(polizas_salud_selected){
        const index = polizas_salud_selected.findIndex((poliza)=>poliza.optionId === action.payload.optionId);
        if(index === -1 && polizas_salud_selected.length < 4){
          polizas_salud_selected.push(action.payload)
        }else if(index !== -1){
          polizas_salud_selected.splice(index,1)
        }
      }else{
        polizas_salud_selected = [action.payload]
      }
      return{
        ...state,
        polizas_salud_selected
      }
    case ActionsTypes.SET_PROGRAMS_SALUD:
      return{
        ...state,
        programs_salud: action.payload
      }
    case ActionsTypes.SALUD_PUT_QUOTATION_OPTIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        put_options_salud_fetching: false,
        put_options_salud_fetched: true
      }
    case ActionsTypes.SET_DATA_QUOTATION_SALUD:
      return {
        ...state,
        id_quotation: action.payload.id,
        quotation_salud: {
          ...state.quotation_salud,
          insureds: action.payload.insureds,
          contact: action.payload && action.payload.contact?
            action.payload.contact:
            {
                name: action.payload.name,
                lastName: action.payload.lastName,
                phone: action.payload.phone,
                email: action.payload.email
            },
        }
      }
    case ActionsTypes.VENTA_SELECT_SALUD:
      return {
        ...state,
        venta_selected: action.payload
      }
    case ActionsTypes.SALUD_GET_PLANILLA_INFO + ActionsTypes.FETCHING:
      return{
        ...state,
        salud_planilla_get_info_fetched: false,
        salud_planilla_get_info_fetching: true,
      }
    case ActionsTypes.SALUD_GET_PLANILLA_INFO + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_planilla_get_info_fetched: true,
        salud_planilla_get_info_fetching: false,
        quotation_salud:{
          ...state.quotation_salud,
          planilla: action.payload.data
        }
      }
    case ActionsTypes.SALUD_PUT_PLANILLA_APPROVED + ActionsTypes.FETCHING:

        return {
          ...state,
          salud_planilla_put_approved_fetching: true,
          salud_planilla_put_approved_fetched: false,
          salud_planilla_put_approved_error: false,
        }

    case ActionsTypes.SALUD_PUT_PLANILLA_APPROVED + ActionsTypes.FETCHED:
      data = action.payload.data;
      if(data.success){
        return {
          ...state,
          salud_planilla_put_approved_fetching: false,
          salud_planilla_put_approved_fetched: true,
          salud_planilla_put_approved_error: false,
        }
      } else {
        return {
          ...state,
          salud_planilla_put_approved_fetched: false,
          salud_planilla_put_approved_fetching: false,
          salud_planilla_put_approved_error: true,
        }
      }
    case ActionsTypes.SET_ERROR_COTIZADOR_SALUD:
        let error = state.error;
        if(!error){
          error = initial_state.error;
        }
        if(action.payload.label == "prospect" || action.payload.label == "contact"){
          return {
            ...state,
            error:{
              ...error,
              cotizador:{
                ...error.cotizador,
                [action.payload.label]:{
                  ...error.cotizador[action.payload.label],
                  [action.payload.label2]:action.payload.value
                }
              }
            }
          }
        }else{
          return {
            ...state,
            error:{
              ...error,
              cotizador:{
                ...error.cotizador,
                [action.payload.label]:action.payload.value
              }
            }
          }
        }

    case ActionsTypes.SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_options_statuse_feching: true,
        salud_options_statuse_feched: false,
      }
    case ActionsTypes.SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_options_statuse_feching: false,
        salud_options_statuse_feched: true,
        salud_options_statuses: action.payload.data
      }
    case ActionsTypes.SALUD_SELECTED_QUOTATION_OPTION_HEALTH:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          data: null,
          feching: false,
          feched: false,
          option: action.payload,
          change_health_state_feching: false,
          change_health_state_feched: false,
          change_health_state_result: null,
          change_health_state_error: null,
        },
      }
    case ActionsTypes.SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES_ALL  + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          feching: true,
          feched: false,
        },
      }
    case ActionsTypes.SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES_ALL  + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          data: action.payload.data,
          feching: false,
          feched: true,
        },
      }

    case ActionsTypes.SALUD_POST_SALUD_CHANGE_HEALTH_STATUS_API  + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          change_health_state_feching: true,
          change_health_state_feched: false,
          change_health_state_error: null,
        },
      }
    case ActionsTypes.SALUD_POST_SALUD_CHANGE_HEALTH_STATUS_API  + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          change_health_state_result: action.payload.data,
          change_health_state_feching: false,
          change_health_state_feched: true,
          change_health_state_error: null,
        },
      }
    case ActionsTypes.SALUD_POST_SALUD_CHANGE_HEALTH_STATUS_API  + ActionsTypes.ERROR:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          change_health_state_feching: false,
          change_health_state_feched: false,
          change_health_state_error: action.payload.data,
        },
      }

    case ActionsTypes.SALUD_API_GET_QUOTE  + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_landing_files: {
          ...state.salud_landing_files,
          quote_feching: true,
          quote_feched: false,
          quote_error: null,
        },
      }
    case ActionsTypes.SALUD_API_GET_QUOTE  + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_landing_files: {
          ...state.salud_landing_files,
          quote_result: action.payload.data,
          quote_feching: false,
          quote_feched: true,
          quote_error: null,
        },
      }
    case ActionsTypes.SALUD_API_GET_QUOTE  + ActionsTypes.ERROR:
      return {
        ...state,
        salud_landing_files: {
          ...state.salud_landing_files,
          quote_feching: false,
          quote_feched: false,
          quote_error: action.payload.data,
        },
      }

    case ActionsTypes.SALUD_API_POST_UPLOAD_FILES  + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_landing_files: {
          ...state.salud_landing_files,
          upload_feching: true,
          upload_feched: false,
          upload_error: null,
        },
      }
    case ActionsTypes.SALUD_API_POST_UPLOAD_FILES  + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_landing_files: {
          ...state.salud_landing_files,
          upload_result: action.payload.data,
          upload_feching: false,
          upload_feched: true,
          upload_error: null,
        },
      }
    case ActionsTypes.SALUD_API_POST_UPLOAD_FILES  + ActionsTypes.ERROR:
      return {
        ...state,
        salud_landing_files: {
          ...state.salud_landing_files,
          upload_feching: false,
          upload_feched: false,
          upload_error: action.payload.data,
        },
      }

    case ActionsTypes.SALUD_API_PUT_HEALTH_STATUS  + ActionsTypes.FETCHING:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          save_health_state_feching: true,
          save_health_state_feched: false,
          save_health_state_error: null,
        },
      }
    case ActionsTypes.SALUD_API_PUT_HEALTH_STATUS  + ActionsTypes.FETCHED:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          save_health_state_result: action.payload.data,
          save_health_state_feching: false,
          save_health_state_feched: true,
          save_health_state_error: null,
        },
      }
    case ActionsTypes.SALUD_API_PUT_HEALTH_STATUS  + ActionsTypes.ERROR:
      return {
        ...state,
        salud_selected_quotation_option_health: {
          ...state.salud_selected_quotation_option_health,
          save_health_state_feching: false,
          save_health_state_feched: false,
          save_health_state_error: action.payload.data,
        },
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.FETCHING:
      return {
        ...state,
        put_principal_option_fetching: true,
        put_principal_option_fetched: false,
        put_principal_option_error: false,
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.FETCHED:
      return {
        ...state,
        put_principal_option_fetching: false,
        put_principal_option_fetched: true,
        put_principal_option_error: false,
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.ERROR:
      return {
        ...state,
        put_principal_option_fetching: false,
        put_principal_option_fetched: false,
        put_principal_option_error: true,
      }
    default:
      return state;
  }
};

