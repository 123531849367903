export const FETCHING = '_FETCHING'
export const ERROR = '_ERROR'
export const FETCHED = '_FETCHED'

//COTIZADOR SALUD
export const SALUD_UPDATE_QUOTATION = "SALUD_UPDATE_QUOTATION"
export const SALUD_UPDATE_QUOTATION_CONTACT = "SALUD_UPDATE_QUOTATION_CONTACT"
export const SALUD_UPDATE_QUOTATION_CONTACT_ALL = "SALUD_UPDATE_QUOTATION_CONTACT_ALL"
export const SALUD_UPDATE_QUOTATION_CONTACT_API = "SALUD_UPDATE_QUOTATION_CONTACT_API"
export const SALUD_UPDATE_QUOTATION_PROSPECT = "SALUD_UPDATE_QUOTATION_PROSPECT"
export const SALUD_UPDATE_QUOTATION_ADD_DEPENT = "SALUD_UPDATE_QUOTATION_ADD_DEPENT"
export const SALUD_UPDATE_QUOTATION_SPLICE_DEPENT = "SALUD_UPDATE_QUOTATION_SPLICE_DEPENT"
export const SALUD_UPDATE_QUOTATION_DEPENT = "SALUD_UPDATE_QUOTATION_DEPENT"
export const SALUD_API_POST_COTIZACIONES = "SALUD_API_POST_COTIZACIONES"
export const SALUD_RESET_QUOTATION = "SALUD_RESET_QUOTATION"
export const SALUD_UPDATE_QUOTATION_PLACE = "SALUD_UPDATE_QUOTATION_PLACE"
export const SALUD_API_POST_UPLOAD_FILES = "SALUD_API_POST_UPLOAD_FILES"
export const SALUD_API_PUT_HEALTH_STATUS = "SALUD_API_PUT_HEALTH_STATUS"
export const SALUD_API_GET_FILTER_BOX = "SALUD_API_GET_FILTER_BOX"
export const SALUD_API_UPDATE_FILTER_BOX_UPDATE = "SALUD_API_UPDATE_FILTER_BOX_UPDATE"
export const SALUD_API_UPDATE_FILTER_BOX = "SALUD_API_UPDATE_FILTER_BOX"
export const SALUD_API_GET_QUOTE = "SALUD_API_GET_QUOTE"
export const SALUD_ADD_REMOVE_PROGRAM = "SALUD_ADD_REMOVE_PROGRAM"
export const SET_PROGRAMS_SALUD = "SET_PROGRAMS_SALUD"
export const SALUD_PUT_QUOTATION_OPTIONS = "SALUD_PUT_QUOTATION_OPTIONS"
export const SALUD_RESET_FETCH_UPDATES = "SALUD_RESET_FETCH_UPDATES"
export const SALUD_POST_CREATE_PLANILLA = "SALUD_POST_CREATE_PLANILLA"
export const SALUD_UPDATE_QUOTATION_BILLING_API = "SALUD_UPDATE_QUOTATION_BILLING_API"
export const SALUD_UPDATE_QUOTATION_BILLING_ALL = "SALUD_UPDATE_QUOTATION_BILLING_ALL"
export const SALUD_PUT_QUOTATION_BILLING_API = "SALUD_PUT_QUOTATION_BILLING_API"
export const SALUD_POST_SALUD_CHANGE_HEALTH_STATUS_API = "SALUD_POST_SALUD_CHANGE_HEALTH_STATUS_API"
export const SALUD_GET_QUOTATION_BILLING = "SALUD_GET_QUOTATION_BILLING"
export const SALUD_GET_QUOTATION_INSUREDS = "SALUD_GET_QUOTATION_INSUREDS"
export const SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES = "SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES"
export const SALUD_SELECTED_QUOTATION_OPTION_HEALTH = "SALUD_SELECTED_QUOTATION_OPTION_HEALTH"
export const SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES_ALL = "SALUD_GET_QUOTATION_OPTION_HEALTH_STATUSES_ALL"
export const SALUD_PUT_QUOTATION_INSURED_API = "SALUD_PUT_QUOTATION_INSURED_API"
export const SALUD_RESET_PLANILLA_ERROR = "SALUD_RESET_PLANILLA_ERROR"
export const SALUD_GET_PLANILLA_INFO = "SALUD_GET_PLANILLA_INFO"
export const SALUD_PUT_PLANILLA_APPROVED = "SALUD_PUT_PLANILLA_APPROVED"
export const API_GET_RELATIONSHIPS_TYPES_SALUD = "API_GET_RELATIONSHIPS_TYPES_SALUD"
export const API_GET_COTIZACIONES_SALUD = "API_GET_COTIZACIONES_SALUD"
export const API_GET_QUOTE_STATUSES_SALUD = "API_GET_QUOTE_STATUSES_SALUD"
export const API_GET_VENTAS_SALUD = "API_GET_VENTAS_SALUD"
export const UPDATE_PROGRAMS = "UPDATE_PROGRAMS"
export const RESET_FETCH_CONTACT = "RESET_FETCH_CONTACT"
export const PUT_SALUD_ALL_INSUREDS = "PUT_SALUD_ALL_INSUREDS"
export const EXTRA_PARAMS = "EXTRA_PARAMS"
export const RESET_EXTRA_PARAMS = "RESET_EXTRA_PARAMS"
export const PUT_QUOTATION_SALUD_OPTIONS = "PUT_QUOTATION_SALUD_OPTIONS"
export const FILTER_COTIZACION_SALUD = "FILTER_COTIZACION_SALUD"
export const FILTER_VENTA_SALUD = "FILTER_VENTA_SALUD"
export const GET_QUOTATION_INFO = "GET_QUOTATION_INFO"
export const SET_CLIENT_TOKEN = "SET_CLIENT_TOKEN"
export const POST_GET_SALUD_QUOTATION_OPTIONS = "POST_GET_SALUD_QUOTATION_OPTIONS"
export const RESET_PAGINATION = "RESET_PAGINATION"
export const SEARCH_COTIZACION_SALUD = "SEARCH_COTIZACION_SALUD"
export const SEARCH_SALE_SALUD = "SEARCH_SALE_SALUD"
export const SET_DATA_QUOTATION_SALUD = "SET_DATA_QUOTATION_SALUD"
export const VENTA_SELECT_SALUD = "VENTA_SELECT_SALUD"
export const GENERAL_POST_VALIDATE_CREDIT_CARD = "GENERAL_POST_VALIDATE_CREDIT_CARD"
export const UPDATE_QUOTE_ID = "UPDATE_QUOTE_ID"
export const GET_QUOTATION_BY_ID = "GET_QUOTATION_BY_ID"
export const GET_QUOTATION_SALE_BY_ID = "GET_QUOTATION_SALE_BY_ID"
export const SET_ERROR_COTIZADOR_SALUD = "SET_ERROR_COTIZADOR_SALUD"
export const RESET_GENERAL_POST_VALIDATE_CREDIT_CARD = "RESET_GENERAL_POST_VALIDATE_CREDIT_CARD"
export const POST_EMIT_SALUD_QUOTATION = "POST_EMIT_SALUD_QUOTATION"
export const SALUD_API_DELETE_DOCUMENT = "SALUD_API_DELETE_DOCUMENT"
export const PUT_QUOTATION_PRINCIPAL_OPTION = "PUT_QUOTATION_PRINCIPAL_OPTION"
export const RESET_UPDATE_CONTACT_QUOTATION = "RESET_UPDATE_CONTACT_QUOTATION"
export const UPDATE_QUOTATION_SALUD_PAY = "UPDATE_QUOTATION_SALUD_PAY"
export const API_POST_SEND_QUOTE = "API_POST_SEND_QUOTE"
export const RESET_SEND = "RESET_SEND"
export const REMOVE_ALL_PROGRAMS_SELECTED = "REMOVE_ALL_PROGRAMS_SELECTED"
export const API_GET_SPECIAL_COVERAGE = "API_GET_SPECIAL_COVERAGE"
export const API_GET_PROGRAMS_SALUD = "API_GET_PROGRAMS_SALUD"
export const SET_PROGRAM_VALUE_SALUD = "SET_PROGRAM_VALUE_SALUD"
export const API_GET_EDIT_INSURERS_SALUD = "API_GET_EDIT_INSURERS_SALUD"
export const SET_PROGRAM_VALUE_SALUD_USUARIO = "SET_PROGRAM_VALUE_SALUD_USUARIO"
export const API_GET_EDIT_INSURERS_USUARIO_SALUD = "API_GET_EDIT_INSURERS_USUARIO_SALUD"
export const API_GET_COACH_USUARIO_SALUD = "API_GET_COACH_USUARIO_SALUD"
