import * as ActionsTypes from "./types";

const ini = {
  about_us_company_description: "",
  about_us_header_subtitle: "",
  about_us_header_title: "",
  color_accent: "#ff5142",
  color_primary: "#0A4259",
  color_secondary: "#72BECC",
  company_description: "",
  header_subtitle: "",
  header_title: "",
  header_background: "",
  isotipo:'assets/images/isotipo.png',
  logo:"assets/images/kerango-h_orange.png",
  logo1:"assets/images/kerango-h_orange.png",
  fetching_home: null,
  fetched_home: null,
};

export default (state = ini, action) => {
  switch (action.type) {

    case ActionsTypes.API_GET_HOME_INFO + ActionsTypes.FETCHING:
      return {
        ...state,
        fetching_home: true,
        fetched_home: false
      }
    case ActionsTypes.API_GET_HOME_INFO + ActionsTypes.FETCHED:
      action.payload.data.color_primary="#0A4259";
      return {
        ...state,
        fetching_home: false,
        fetched_home: true,
        ...action.payload.data
      }
    default:
      return state;
  }
};
