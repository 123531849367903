export const FETCHING = '_FETCHING'
export const ERROR = '_ERROR'
export const FETCHED = '_FETCHED'

// CREATE
export const UPDATE_CREATE_EMPRESAS = "UPDATE_CREATE_EMPRESAS"
export const UPDATE_CREATE_EMPRESAS_ADMIN = "UPDATE_CREATE_EMPRESAS_ADMIN"
export const UPDATE_CREATE_EMPRESAS_CONFIGS = "UPDATE_CREATE_EMPRESAS_CONFIGS"
export const UPDATE_CREATE_EMPRESAS_FILES = "UPDATE_CREATE_EMPRESAS_FILES"
export const UPDATE_CREATE_EMPRESAS_INITIAL = "UPDATE_CREATE_EMPRESAS_INITIAL"
export const API_GET_EMPRESAS_TYPES = "API_GET_EMPRESAS_TYPES"
export const API_GET_EMPRESAS = "API_GET_EMPRESAS"
export const API_GET_INSURERS = "API_GET_INSURERS"
export const API_GET_SECTORS = "API_GET_SECTORS"
export const API_GET_COUNTRIES_CREATE_EMPRESAS = "API_GET_COUNTRIES_CREATE_EMPRESAS"
export const API_GET_CITIES_CREATE_EMPRESAS = "API_GET_CITIES_CREATE_EMPRESAS"
export const API_GET_FISCAL_CITIES_CREATE_EMPRESAS = "API_GET_FISCAL_CITIES_CREATE_EMPRESAS"
export const API_PATCH_ACTIVE_EMPRESAS = "API_PATCH_ACTIVE_EMPRESAS"
export const UPDATE_CREATE_INFO = "UPDATE_CREATE_INFO"
export const API_POST_CREATE_EMPRESAS = "API_POST_CREATE_EMPRESAS"
export const EMPRESAS_SELECT = "EMPRESAS_SELECT"
export const UPDATE_CREATE_EMPRESAS_INSURERS = "UPDATE_CREATE_EMPRESAS_INSURERS";
export const RESET_CREATE_EMPRESAS = "RESET_CREATE_EMPRESAS";
export const UPDATE_CREATE_EMPRESAS_SECTORS = "UPDATE_CREATE_EMPRESAS_SECTORS"
export const UPDATE_EDIT_EMPRESAS_SECTORS = "UPDATE_EDIT_EMPRESAS_SECTORS"
export const API_CREATE_TARJETA = "API_CREATE_TARJETA"
export const API_EDIT_TARJETA = "API_EDIT_TARJETA"
export const API_DELETE_TARJETA = "API_DELETE_TARJETA"
export const API_GET_TARJETA = "API_GET_TARJETA"
export const RESET_TARJETA_CONST = "RESET_TARJETA_CONST"
export const API_POST_UPDATE_EMPRESA_SALUD = "API_POST_UPDATE_EMPRESA_SALUD"
export const API_GET_EDIT_INSURERS_SALUD = "API_GET_EDIT_INSURERS_SALUD"


// EDIT
export const UPDATE_EDIT_EMPRESAS = "UPDATE_EDIT_EMPRESAS"
export const UPDATE_EDIT_EMPRESAS_INITIAL = "UPDATE_EDIT_EMPRESAS_INITIAL"
export const UPDATE_EDIT_EMPRESAS_CONFIGS = "UPDATE_EDIT_EMPRESAS_CONFIGS"
export const UPDATE_EDIT_EMPRESAS_FILES = "UPDATE_EDIT_EMPRESAS_FILES"
export const API_GET_EDIT_INSURERS = "API_GET_EDIT_INSURERS"
export const UPDATE_EDIT_EMPRESAS_INSURERS = "UPDATE_EDIT_EMPRESAS_INSURERS"
export const API_PUT_EDIT_EMPRESAS = "API_PUT_EDIT_EMPRESAS"
export const RESET_EDIT_EMPRESAS = "RESET_EDIT_EMPRESAS"
export const API_UPDATE_EMPRESAS_SELECT = "API_UPDATE_EMPRESAS_SELECT"
export const UPDATE_EDIT_EMPRESAS_ADMIN = "UPDATE_EDIT_EMPRESAS_ADMIN"

// filters
export const API_EMPRESAS_FILTERS_COUNTRIES = "API_EMPRESAS_FILTERS_COUNTRIES"
export const API_EMPRESAS_FILTERS_CITIES = "API_EMPRESAS_FILTERS_CITIES"
export const FILTER_EMPRESAS = "FILTER_EMPRESAS"
export const API_GET_EMPRESA = "API_GET_EMPRESA"
export const EMPRESAS_SET_ERRORS = "EMPRESAS_SET_ERRORS"
export const EMPRESAS_SELECT_ID = "EMPRESAS_SELECT_ID"
