import * as AuthActions from "../redux/authModule/action";

export const FILE_URL_BASE = "https://smart-and-smart.s3.amazonaws.com"
export const DOMINE = (window.location.hostname === 'localhost' ? "http" : "https") + "://" + window.location.hostname;
// export const DOMINE = 'https://staging-b2b.smartandsmart.net';
export const DOMINE_B2C = 'https://kerango.com'
export const PATH_DEVELOMENT = "https://api.kerango.com/";
export const ENVIRONMENT = "production";
export const AGENTE = "Agente";
export const SUPER_ADMIN = "Super Admin";
