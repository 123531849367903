import * as ActionsTypes from "./types";

const ini = {
  quotation: {
    origin: '',//"COL",
    destinations: [
      {
        start: '',
        end: '',
        iso3: ''
      }
    ],
    travelType: 1,//2
    numberDays: null, //30
    start: '',// "15/05/2020"
    insureds: [
      {
        gender: '',//"male",
        specialCoverage: null,
        birthDate: '',//"09/05/1995",
        nationality: '',//"VEN"
        months: null,
        firstName: '',
        lastName: '',
        document: '',
        isPrimary: true
      }
    ],
    filters: {
      insurers: [],
      medicalAssistance: {
        min: 0,
        max: 250000
      },
      transport: {
        min: 0,
        max: 9999999
      },
      cancel: {
        min: 0,
        max: 9999999
      },
      tripInterruption: {
        min: 0,
        max: 9999999
      },
      lostLuggage: {
        min: 0,
        max: 9999999
      },
      deductible: {
        min: 0,
        max: 9999999
      },
      pregnancy: false,
      preexistingDiseases: false,
      extremeSports: false
    },
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      phone: ""
    },
    emergency_contact: null,
    name: "",
    email: "",
    phone: "",
    message: "",
    card: {
      month: '',
      year: '',
      name: '',
      cvc: '',
      number: ''
    },
    optionId: "",
    invoiceTo: {
      country: "",
      documentType: "",
      firstName: "",
      lastName: "",
      document: "",
      phone: "",
      email: "",
      address: "",
      address2: "",
      zip: "",
      state: "",
      city: "",
      birthDate: ""
    },
  },
  error:{
    cotizador:{
      type: false,
      paymentType: false,
      contact:{
        firstName: false,
        lastName: false,
        email:false,
        phone:false
      },
      beneficiary:{
        name: false,
        lastName: false,
        birthDate: false,
        residency: false,
        residency2: false,
        gender: false
      },
      insureds:[]
    }
  },
  pagination: {
    step: 15,
    page: 0,
    pages: 0,
    total: 0,
  },
  filter_search: "",
  cotizaciones: [],
  cotizaciones_filter: [],
  ventas: [],
  ventas_filter: [],
  cotizacion_selected: {},
  venta_selected: {},
  specialCoverages: [],
  document_types: [],
  payment_types: [],
  program_types: [],
  relationship_types: [],
  statuses: [],
  statuses_feching: false,
  statuses_feched: false,
  insurers: [],
  polizas_selected: [],
  id_quotation: null,
  programs: [],
  travel_types: [],
  success: null,
  messages: "",
  program_selected: {},
  fetching: null,
  fetched: null,
  send_fetching: null,
  send_fetched: null,
  send_data: null,
  programs_fetching: null,
  programs_fetched: null,
  programs_error: null,
  programs_error_message: '',
  result_purchase: null,
  fetched_purchase: null,
  fetching_purchase: null,
  error_purchase: null,
  quotation_client_fetching: false,
  quotation_client_fetched: false,
  agent: null,
  xls: null,
  send_quote: {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    confir: false,
    optionsId: '',
  },
  search_param_quotes: '',
  search_param_sale: '',
  search_param_quotes_loading: false,
  search_param_sale_loading: false,
  insureds_put_fetched: false,
  contact_update_fetched: false,
  post_answers_fetched: false,
  emit_card_fetched: false,
  billing_update_fetched: false,
  billing_update_fetching: false,
  billing_update_result: false,
  billing_update_error: false,
  insureds_get_fetched: false,
  insureds_put_fetched: false,
  insureds_put_fetching: false,
  insureds_put_result: false,
  insureds_put_all_fetched: false,
  insureds_put_all_error: false,
  put_options_fetching: false,
  put_options_fetched: false,
  contact_emergency_update_fetched: false,
  contact_emergency_update_fetching: false,
  contact_emergency_update_result: false,
  contact_emergency_update_error: false,
  put_principal_option_fetching: false,
  put_principal_option_fetched: false,
  put_principal_option_error: false,
  get_cotizacion_fetching: false,
  get_cotizacion_fetched: false,
  get_cotizacion_error: false,
  extra_params: {},
  page_param_sale:  0,
  page_param_quotes: 0,
  filters: {},
  filters_fetching: false,
  filters_fetched: false,
  quote_loaded:false,
  update_contact_quotation:false,
  validate_type_travel: false,
  validate_travel_duration: false,
  validate_contact: false,
  validate_insureds: false,
  changeEmail: false,
  sale:{},
  get_programs_fetching: false,
  get_programs_fetched: false,
  get_programs_error: false,
  programs_id: [],
  programsValues: {},
  programsValuesUsuario: {},
  insurers_sale: []
};
const temp = JSON.parse(JSON.stringify(ini));

const initial_state = ini;

export default (state = ini, action) => {
  let items = [];
  switch (action.type) {
    case ActionsTypes.CHANGE_VALUE_QUOTATION:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          [action.payload.input]: action.payload.value
        }
      }
    case ActionsTypes.API_GET_DOCUMENT_TYPES + ActionsTypes.FETCHED:
      return {
        ...state,
        document_types: action.payload.data,
      }

    case ActionsTypes.API_GET_INSURERS_SALES + ActionsTypes.FETCHED:
      return {
        ...state,
        insurers_sale: action.payload.data,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_VIAJE + ActionsTypes.FETCHING:
      return {
        ...state,
        fetching_insurers_viaje: true,
        fetched_insurers_viaje:false
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_VIAJE + ActionsTypes.FETCHED:
      const dataValues = action.payload.data;
      let programsValues = {}
      if(dataValues){
        for(let i = 0; i < dataValues.length; i++) {
          const dataValue = dataValues[i];
          for(let j = 0; j < dataValue.programs.length; j++) {
            const program = dataValue.programs[j];
            if(program.active){
              programsValues[program.id] = program.percentage
            }
          }
        }
      }
      return {
        ...state,
        programsValues,
        fetching_insurers_viaje: false,
        fetched_insurers_viaje: true
      }
      case ActionsTypes.API_GET_COACH_USUARIO_VIAJE + ActionsTypes.FETCHING:
        return {
          ...state,
          get_programs_fetching: true,
          get_programs_fetched: false,
          get_programs_error: false,
        }
    case ActionsTypes.API_GET_COACH_USUARIO_VIAJE + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          get_programs_id_fetching: true,
          get_programs_id_fetched: false,
          get_programs_id_error: false,
          programs_id: action.payload.data,
        }
      }else{
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: false,
          get_programs_error: true,
          programs_id: [],
        }
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_USUARIO_VIAJE + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched_insurers_viaje: false,
        fetching_insurers_viaje: true,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_USUARIO_VIAJE + ActionsTypes.FETCHED:
      const dataValuesUsuario = action.payload.data;
      let dataNewValuesUsuario = {}
      if(dataValuesUsuario){
        for(let i = 0; i < dataValuesUsuario.length; i++) {
          const dataValue = dataValuesUsuario[i];
          for(let j = 0; j < dataValue.programs.length; j++) {
            const program = dataValue.programs[j];
            if(program.active){
              dataNewValuesUsuario[program.id] = program.percentage
            }
          }
        }
      }
      return {
        ...state,
        programsValuesUsuario: dataNewValuesUsuario,
        fetched_insurers_viaje: true,
        fetching_insurers_viaje: false,
      }
    case ActionsTypes.API_GET_QUOTE_STATUSES + ActionsTypes.FETCHING:
      return {
        ...state,
        statuses: [],
        statuses_feching: true,
        statuses_feched: false,
      }
    case ActionsTypes.API_GET_QUOTE_STATUSES + ActionsTypes.FETCHED:
      let items = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.name
        };
      })
      items.unshift({
        id: null,
        text: "Estado de la cotizacion"
      });
      return {
        ...state,
        statuses: items,
        statuses_feching: false,
        statuses_feched: true,
      }
    case ActionsTypes.API_GET_INSURERS_QUOTES + ActionsTypes.FETCHED:
      items = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.name
        };
      })
      return {
        ...state,
        insurers: items,
      }
    
    case ActionsTypes.GET_QUOTATION_SALE_BY_ID + ActionsTypes.FETCHING:
      return{
        ...state,
        get_sale_fetched:false,
        get_sale_fetching:true,
        get_sale_error:false
      }
    case ActionsTypes.GET_QUOTATION_SALE_BY_ID + ActionsTypes.FETCHED:
      if(!!action.payload.data.quote){
        return {
          ...state,
          sale:{
            ...state.sale,
            ...action.payload.data.quote,
          },
          get_sale_fetched:true,
          get_sale_fetching:false,
          get_sale_error:false
        }
      }else{
        return {
          ...state,
          get_sale_fetched:false,
          get_sale_fetching:false,
          get_sale_error:true
        }
      }
    case ActionsTypes.GET_QUOTATION_SALE_BY_ID + ActionsTypes.ERROR:
        return {
          ...state,
          sale:{
            ...action.payload.data.quote,
          },
          get_sale_fetched:false,
          get_sale_fetching:false,
          get_sale_error:true
        }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.FETCHING:
      return {
        ...state,
        put_principal_option_fetching: true,
        put_principal_option_fetched: false,
        put_principal_option_error: false,
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.FETCHED:
      return {
        ...state,
        put_principal_option_fetching: false,
        put_principal_option_fetched: true,
        put_principal_option_error: false,
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.ERROR:
      return {
        ...state,
        put_principal_option_fetching: false,
        put_principal_option_fetched: false,
        put_principal_option_error: true,
      }
    case ActionsTypes.API_GET_FILTER_BOX + ActionsTypes.FETCHED:
      let payloadData = action.payload.data;

      // set default values for companies filter
      const companiesFilterIndex = payloadData.findIndex((filter) => filter.code === 'companies');
      if (companiesFilterIndex !== -1) {
        payloadData[companiesFilterIndex].aux.default = payloadData[companiesFilterIndex].aux.items.map(
          (company) => company.defaultValue === 1 ? company.id : false
        );
      }
      // set default values for options filter
      const optionsFilterIndex = payloadData.findIndex(filter => filter.code === 'options');
      if (optionsFilterIndex !== -1) {
        payloadData[optionsFilterIndex].aux.default = {};
        for(let option of payloadData[optionsFilterIndex].aux.options) {
          payloadData[optionsFilterIndex].aux.default[option.key] = option.defaultValue;
        }
      }

      return {
        ...state,
        filters: payloadData,
        filters_fetching: false,
        filters_fetched: true
      }

    case ActionsTypes.API_POST_SEND_QUOTE + ActionsTypes.FETCHING:
      return {
        ...state,
        send_fetched: false,
        send_fetching: true,
        send_data: null
      }
    case ActionsTypes.API_POST_SEND_QUOTE + ActionsTypes.FETCHED:
      return {
        ...state,
        send_fetched: true,
        send_fetching: false,
        send_data: action.payload.data
      }

    case ActionsTypes.RESET_UPDATE_CONTACT_QUOTATION:
      return {
        ...state,
        update_contact_quotation:false
      }

    case ActionsTypes.RESET_SEND:
      return {
        ...state,
        send_fetched: null,
        send_fetching: null,
        send_data: null,
        program_selected: [],
        send_quote: {
          name: '',
          lastName: '',
          email: '',
          phone: '',
          confir: false,
          optionsId: '',
        }
      }
    case ActionsTypes.RESET_FETCH_CONTACT:
      return {
        ...state,
        contact_update_fetched: false,
        insureds_put_fetched: false,
        post_answers_fetched: false,
        emit_card_fetched: false,
        contact_emergency_update_fetched: false,
        insureds_put_all_fetched: false,
      }
    case ActionsTypes.RESET_SEND_RESULT:
      return {
        ...state,
        send_fetched: null,
        send_fetching: null,
        send_data: null,
      }

    case ActionsTypes.COTIZACION_SELECT:
      return {
        ...state,
        cotizacion_selected: {
          ...action.payload,
          options: []
        },
        id_quotation: action.payload.id
      }

    case ActionsTypes.VENTA_SELECT:
      return {
        ...state,
        venta_selected: action.payload
      }
    case ActionsTypes.VALIDATE_QUOTE_FORM:
      return {
        ...state,
        validate_type_travel: true,
        validate_travel_duration: true,
        validate_contact: true,
        validate_insureds: true,
      }
    case ActionsTypes.OFF_VALIDATION_TYPE:
      let type = action.payload
      if(type === "type_travel"){
        return {
          ...state,
          validate_type_payment: false,
        }
      }else if(type === "travel_duration"){
        return {
          ...state,
          validate_travel_duration: false,
        }
      }else if(type === "contact"){
        return {
          ...state,
          validate_contact: false,
        }
      }else if(type === "insureds"){
        return {
          ...state,
          validate_insureds: false,
        }
      }else{
        return {
          ...state,
        }
      }

    case ActionsTypes.RESET_REQUEST:
      return {
        ...state,
        fetched_purchase: null,
        fetching_purchase: null
      }
    case ActionsTypes.SAVE_SEND_QUOTE:
      return {
        ...state,
        send_quote: action.payload
      }

    case ActionsTypes.ADD_POLIZA_COMPARAR:
      let {polizas_selected} = state;
      if (!polizas_selected) {
        polizas_selected = [];
      }
      polizas_selected.push(action.payload)
      return {
        ...state,
        polizas_selected
      }

    case ActionsTypes.REMOVE_POLIZA_COMPARAR:
      polizas_selected = state.polizas_selected;
      polizas_selected.splice(action.payload, 1)
      return {
        ...state,
        polizas_selected
      }

    case ActionsTypes.RESET_PROGRAMS_SELECTED:
      return {
        ...state,
        polizas_selected: [],
        pagination: {
          step: 15,
          page: 0,
          pages: 0,
          total: 0,
        },
      }

    case ActionsTypes.GET_QUOTATION_SALE_EMERGENCY_CONTACT + ActionsTypes.FETCHED:
      return {
        ...state,
        sale: {
          ...state.sale,
          emergency_contact: action.payload.data.length > 0?action.payload.data[0]:{}
        }
      }

    case ActionsTypes.GET_QUOTATION_EMERGENCY_CONTACT + ActionsTypes.FETCHED:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          emergency_contact: action.payload.data.length > 0?action.payload.data[0]:{}
        }
      }

    case ActionsTypes.CHANGE_SEARCH_QUOTES:
      return {
        ...state,
        search_param_quotes: action.payload
      }
    case ActionsTypes.API_GET_PAYMENT_TYPES_VIAJE + ActionsTypes.FETCHED:
      return {
        ...state,
        payment_types: action.payload.data,
      }
    case ActionsTypes.API_GET_PROGRAM_TYPES_VIAJE + ActionsTypes.FETCHED:
      return {
        ...state,
        program_types: action.payload.data,
      }
    case ActionsTypes.GET_QUOTATION_BY_ID + ActionsTypes.FETCHING:
      return{
        ...state,
        quote_loaded:false,
      }
    case ActionsTypes.GET_QUOTATION_BY_ID + ActionsTypes.FETCHED:
      if(!!action.payload.data.quote){
        const data_test = action.payload.data.quote;
        const moment_test = require('moment');
        return {
          ...state,
          id_quotation:action.payload.data.quote.id,
          quotation:{
            ...state.quotation,
            ...action.payload.data.quote,
            insureds:state.quotation.insureds
          },
          quote_loaded:true,
        }
      }else{
        return {
          ...state
        }
      }
    case ActionsTypes.POST_GET_VIAJE_QUOTATION_OPTIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        post_get_options_celulas_fetching: false,
        post_get_options_celulas_fetched: true,
        polizas_selected: action.payload.data.programs
      }
    case ActionsTypes.UPDATE_QUOTE_ID:
      return {
        ...state,
        id_quotation: action.payload
      }
    case ActionsTypes.API_POST_PURCHASE + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched_purchase: false,
        fetching_purchase: true
      }
    case ActionsTypes.API_POST_PURCHASE + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          fetched_purchase: true,
          fetching_purchase: false,
          error_purchase: false,
          result_purchase: action.payload.data,
        }
      }else{
        return {
          ...state,
          fetched_purchase: false,
          fetching_purchase: false,
          error_purchase: true,
          result_purchase: action.payload.data,
        }
      }
      
    case ActionsTypes.API_POST_PURCHASE + ActionsTypes.ERROR:
      return {
        ...state,
        fetched_purchase: true,
        fetching_purchase: false,
        error_purchase: true,
        result_purchase: action.payload.data,
      }
    case ActionsTypes.ADD_INSURED:
      let {insureds} = state.quotation
      insureds.push({
        gender: '',//"male",
        specialCoverage: null,
        birthDate: '',//"09/05/1995",
        nationality: '',//"VEN"
        months: null,
        firstName: '',
        lastName: '',
        document: '',
        isPrimary: true
      })
      return {
        ...state,
        quotation: {
          ...state.quotation,
          insureds
        }
      }
    case ActionsTypes.REMOVE_INSURED:
      insureds = state.quotation.insureds;
      insureds.splice(action.payload, 1);
      return {
        ...state,
        quotation: {
          ...state.quotation,
          insureds
        }
      }
    case ActionsTypes.ADD_DESTINATION:
      let destinations = state.quotation.destinations;
      destinations.push({
        start: '',
        end: '',
        iso3: ''
      })
      return {
        ...state,
        quotation: {
          ...state.quotation,
          destinations
        }
      }
    case ActionsTypes.REMOVE_DESTINATION:
      destinations = state.quotation.destinations;
      destinations.splice(action.payload, 1);

      return {
        ...state,
        quotation: {
          ...state.quotation,
          destinations
        }
      }
    case ActionsTypes.UPDATE_ORIGIN:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          origin: action.payload
        }
      }
    case ActionsTypes.UPDATE_DESTINATIONS:
      destinations = state.quotation.destinations;
      destinations[action.payload.index].iso3 = action.payload.opcion;
      return {
        ...state,
        quotation: {
          ...state.quotation,
          destinations
        }
      }
    case ActionsTypes.PUT_TRAVEL_ALL_INSUREDS:
      if(!!action.payload){
        return {
          ...state,
          insureds_put_all_fetched: true,
          insureds_put_all_error: false,
        }
      }else{
        return {
          ...state,
          insureds_put_all_fetched: false,
          insureds_put_all_error: true,
        }
      }


    case ActionsTypes.RESET_FETCH_BILLING:
      return {
        ...state,
        billing_update_fetched: false,
      }
    case ActionsTypes.RESET_EXTRA_PARAMS:
      return {
        ...state,
        extra_params: null,
      }
    case ActionsTypes.GET_QUOTATION_SALE_BILLING + ActionsTypes.FETCHED:
      return {
        ...state,
        sale: {
          ...state.sale,
          billing: action.payload.data[0]
        }
      }
    case ActionsTypes.GET_QUOTATION_BILLING + ActionsTypes.FETCHED:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          billing: action.payload.data[0]
        }
      }
    case ActionsTypes.PUT_QUOTATION_BILLING_API + ActionsTypes.FETCHING:
      return {
        ...state,
        billing_update_fetched: false,
        billing_update_fetching: true,
        billing_update_result: null,
      }
    case ActionsTypes.PUT_QUOTATION_BILLING_API + ActionsTypes.FETCHED:
      if(!!action.payload.data.success){
        return {
          ...state,
          billing_update_fetched: true,
          billing_update_fetching: false,
          billing_update_result: action.payload.data,
          billing_update_error: false
        }
      }else{
        return {
          ...state,
          billing_update_fetched: false,
          billing_update_fetching: false,
          billing_update_result: action.payload.data,
          billing_update_error:true
        }
      }
    
    case ActionsTypes.UPDATE_QUOTATION_BILLING_API + ActionsTypes.FETCHING: 
    return {
      ...state,
      billing_update_fetched: false,
      billing_update_fetching: true,
      billing_update_result: {},
      billing_update_error: false,
    }
    case ActionsTypes.UPDATE_QUOTATION_BILLING_API + ActionsTypes.FETCHED:
      if(!!action.payload.data.success){
        return {
          ...state,
          billing_update_fetched: true,
          billing_update_fetching: false,
          billing_update_result: action.payload.data,
          billing_update_error: false,
          quotation:{
            ...state.quotation,
            billing:action.payload.data.info
          }
        }
      }else{
        return {
          ...state,
          billing_update_fetched: false,
          billing_update_fetching: false,
          billing_update_result: action.payload.data,
          billing_update_error:true
        }
      }
    
    case ActionsTypes.CLEAR_ERROR_BILLING:
      return {
        ...state,
        billing_update_error:false
      }
    case ActionsTypes.GET_QUOTATION_INSUREDS + ActionsTypes.FETCHED:
      let insureds_aux = action.payload.data;
      for(let i = 0; i < insureds_aux.length; i++){
        if(insureds_aux[0].relationship === "Primary"){
          insureds_aux[0].isPrimary = true;
          break;
        }
      }
      return {
        ...state,
        insureds_get_fetched: true,
        quotation: {
          ...state.quotation,
          insureds: insureds_aux
        }
      }
    case ActionsTypes.PUT_QUOTATION_INSURED_API + ActionsTypes.FETCHING:
      return {
        ...state,
        insureds_put_fetched: false,
        insureds_put_fetching: true,
        insureds_put_error: false,
        insureds_put_result: null,
      }
    case ActionsTypes.PUT_QUOTATION_INSURED_API + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.success){
        return {
          ...state,
          insureds_put_fetched: true,
          insureds_put_fetching: false,
          insureds_put_error: false,
          insureds_put_result: action.payload.data,
        }
      }else{
        return {
          ...state,
          insureds_put_fetched: false,
          insureds_put_fetching: false,
          insureds_put_error: true,
          insureds_put_result: action.payload.data,
        }
      }
      
      case ActionsTypes.GET_QUOTATION_QUESTIONS + ActionsTypes.FETCHED:
        let done_answers = true;
        let data_questions = action.payload.data.items[0];
        for (let i = 0; i < data_questions.categories.length; i++) {
          let category = data_questions.categories[i];
          for (let j = 0; j < category.questions.length; j++) {
            let question = category.questions[j];
            let answer = question.answer;
            if (answer === false) {
              done_answers = false
            } else if (answer == null) {
              done_answers = false
            }
          }
        }
        return {
          ...state,
          quotation: {
            ...state.quotation,
            done_answers,
          },
          questions: action.payload.data.items,
        }
    case ActionsTypes.RESET_FETCH_BILLING:
      return {
        ...state,
        billing_update_fetched: false,
      }
    case ActionsTypes.UPDATE_QUOTATION_PAY:
      return{
        ...state,
        quotation: {
          ...state.quotation,
          card: action.payload,
        },
      }
    case ActionsTypes.UPDATE_CONTACT:
      let contact = state.quotation.contact;
      contact[action.payload.label] = action.payload.value;
      return {
        ...state,
        contact
      }
    case ActionsTypes.UPDATE_CONTACT_EMERGENCY:
      let emergency_contact = state.quotation.emergency_contact;
      emergency_contact[action.payload.label] = action.payload.value;
      return {
        ...state,
        emergency_contact
      }
    case ActionsTypes.UPDATE_QUOTATION_CONTACT_ALL:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          contact: action.payload
        }
      }
    case ActionsTypes.UPDATE_QUOTATION_CONTACT_EMERGENCY_ALL:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          emergency_contact: action.payload
        }
      }
    case ActionsTypes.UPDATE_QUOTATION_BILLING_ALL:
      return {
        ...state,
        quotation:{
          ...state.quotation,
          billing:action.payload
        }
      }
    case ActionsTypes.UPDATE_PROGRAMS:
      return {
        ...state,
        polizas_selected: [action.payload]
      }
    case ActionsTypes.UPDATE_QUOTATION_CONTACT_API + ActionsTypes.FETCHING:
      return {
        ...state,
        contact_update_fetching: true,
        contact_update_fetched: false,
        contact_update_result: null,
      }
    case ActionsTypes.UPDATE_QUOTATION_CONTACT_API + ActionsTypes.FETCHED:
      return {
        ...state,
        contact_update_fetched: true,
        contact_update_fetching: false,
        contact_update_result: action.payload.data,
      } 
      
    case ActionsTypes.GET_SALE_BY_ID + ActionsTypes.FETCHING:
      return {
        ...state,
        sale_fetching: true,
        sale_fetched: false,
        sale_error: false,
      }
    case ActionsTypes.GET_SALE_BY_ID + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.success){
        return {
          ...state,
          sale_fetching: false,
          sale_fetched: true,
          sale_error: false,
          sale: action.payload.data.sale
        }
      }else{
        return {
          ...state,
          sale_fetching: false,
          sale_fetched: false,
          sale_error: true,
          sale:{}
        }
      }
    case ActionsTypes.UPDATE_QUOTATION_CONTACT_EMERGENCY_API + ActionsTypes.FETCHING:
      return {
        ...state,
        contact_emergency_update_fetching: true,
        contact_emergency_update_fetched: false,
        contact_emergency_update_result: null,
      }
    case ActionsTypes.UPDATE_QUOTATION_CONTACT_EMERGENCY_API + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.success && action.payload.data.info){
        return {
          ...state,
          contact_emergency_update_fetched: true,
          contact_emergency_update_fetching: false,
          contact_emergency_update_result: action.payload.data,
          contact_emergency_update_error: false,
          quotation:{
            ...state.quotation,
            emergency_contact: action.payload.data.info
          }
        }
      }else if(action.payload.data && action.payload.data.success){
        return {
          ...state,
          contact_emergency_update_fetched: true,
          contact_emergency_update_fetching: false,
          contact_emergency_update_result: action.payload.data,
          contact_emergency_update_error: false
        }
      }else{
        return {
          ...state,
          contact_emergency_update_fetched: false,
          contact_emergency_update_fetching: false,
          contact_emergency_update_error: true,
          contact_emergency_update_result: action.payload.data,
        }
      }
    case ActionsTypes.UPDATE_INSURED:
      insureds = state.quotation.insureds;
      if (action.payload.label === "isPrimary") {
        insureds[action.payload.index][action.payload.label] = (insureds[action.payload.index][action.payload.label]) ? (!insureds[action.payload.index][action.payload.label]) : true;
      } else {
        insureds[action.payload.index][action.payload.label] = action.payload.value;
      }

      return {
        ...state,
        quotation: {
          ...state.quotation,
          insureds
        }
      }
    case ActionsTypes.UPDATE_INSUREDS:
      return {
        ...state,
        quotation: {
          ...state.quotation,
          insureds: action.payload
        }
      }
    case ActionsTypes.EXTRA_PARAMS:
      return {
        ...state,
        extra_params: action.payload,
      }
    case ActionsTypes.API_GET_COTIZACIONES + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched: false,
        fetching: true,
        search_param_quotes_loading: false,
      };
    case ActionsTypes.API_GET_VENTAS + ActionsTypes.FETCHED:
      return {
        ...state,
        ventas: action.payload.data.sales,
        ventas_filter: action.payload.data.sales,
        pagination: action.payload.data.pagination,
        fetched: true,
        fetching: false
      };
    case ActionsTypes.VIAJE_PUT_QUOTATION_OPTIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        put_options_fetching: false,
        put_options_fetched: true
      }
    case ActionsTypes.API_GET_VENTAS + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched: false,
        fetching: true,
        search_param_sale_loading: false,
      };
    case ActionsTypes.API_GET_COTIZACIONES + ActionsTypes.FETCHED:
      return {
        ...state,
        cotizaciones: action.payload.data.quotes,
        cotizaciones_filter: action.payload.data.quotes,
        pagination: action.payload.data.pagination,
        fetched: true,
        fetching: false
      };
    case ActionsTypes.API_GET_COTIZACION + ActionsTypes.FETCHING:
      return {
        ...state,
        quotation_client_fetched: false,
        quotation_client_fetching: true
      };
    case ActionsTypes.API_GET_COTIZACION + ActionsTypes.FETCHED:
      let quote = temp.quotation;
      let agentData = null;
      let programs = [];
      let id_quotation = null;
      if (action.payload.data.success) {
        const quoteData = action.payload.data.quote;
        const agent = action.payload.data.agent;
        const moment = require('moment');
        id_quotation = quoteData.id;
        quote = {
          id: quoteData.id,
          origin: quoteData.origin.iso3,
          originData: quoteData.origin,
          destinations: quoteData.destinations.map(item => {
            const start = moment(item.start.substring(0, 10), 'YYYY-MM-DD');
            const end = moment(item.end.substring(0, 10), 'YYYY-MM-DD');
            return {
              start: start.format('DD/MM/YYYY'),
              end: end.format('DD/MM/YYYY'),
              iso3: item.country.iso3,
              name: item.country.name,
            }
          }),
          travelType: quoteData.travelType.id,//2
          travelTypeData: quoteData.travelType, // {}
          numberDays: null, //30
          start: moment(quoteData.effectiveDate.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY'),// "15/05/2020"
          end: moment(quoteData.expirationDate.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY'),// "15/05/2020"
          insureds: quoteData.insureds.map(item => {
            return {
              gender: item.gender,//"male",
              specialCoverage: item.specialCoverage ? item.specialCoverage.id : null,
              specialCoverageData: item.specialCoverage,
              birthDate: moment(item.birth.substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY'),//"09/05/1995",
              nationality: item.citizenship.iso3,//"VEN"
              months: null,
              firstName: item.firstName,
              lastName: item.lastName,
              document: item.document,
              isPrimary: false
            }
          }),
          contact: {
            firstName: quoteData.firstName,
            lastName: quoteData.lastName,
            phone: quoteData.phone,
            email: quoteData.email
          },
          agent: quoteData
        }
        agentData = {
          name: agent.name,
          lastName: agent.lastName,
          phone: agent.phone,
          email: agent.email,
          countries: agent.countries.map(item => {
            return {
              country: item.country,
              city: item.city,
            }
          }),
          image: agent.image
        };
        programs = action.payload.data.programs;
      }
      return {
        ...state,
        id_quotation,
        quotation: quote,
        quotation_client_fetched: true,
        quotation_client_fetching: false,
        agent: agentData,
        polizas_selected: programs
      };
    case ActionsTypes.GET_POLIZAS_COTIZACION + ActionsTypes.FETCHED:
      return {
        ...state,
        cotizacion_selected: {
          ...state.cotizacion_selected,
          options: action.payload.data.programs,
        },
        polizas_selected: action.payload.data.programs
      }
    case ActionsTypes.API_GET_SPECIAL_COVERAGE + ActionsTypes.FETCHED:
      return {
        ...state,
        specialCoverages: action.payload.data
      }
    case ActionsTypes.UPDATE_FIRST_RANGE_DATE:
      destinations = [];
      destinations = state.quotation.destinations;
      destinations[0] = {
        ...destinations[0],
        start: action.payload.start,
        end: action.payload.end,
      }
      return {
        ...state,
        quotation: {
          ...state.quotation,
          start: action.payload.start,
          destinations
        }
      };
    case ActionsTypes.UPDATE_RANGE_DATE:
      destinations = [];
      destinations = state.quotation.destinations;
      destinations[action.payload.index] = {
        ...destinations[action.payload.index],
        start: action.payload.date.start,
        end: action.payload.date.end,
      }
      return {
        ...state,
        quotation: {
          ...state.quotation,
          destinations
        }
      };
    case ActionsTypes.API_GET_TRAVEL_TYPES + ActionsTypes.FETCHED:
      return {
        ...state,
        travel_types: action.payload.data,
        quotation: {
          ...state.quotation,
          emergency_contact: null
        }
      }
    case ActionsTypes.API_POST_COTIZACIONES + ActionsTypes.FETCHING:
      return {
        ...state,
        programs_fetching: true,
        programs_fetched: false,
        changeEmail: false,
        programs_error: false,
      }
    case ActionsTypes.API_POST_COTIZACIONES + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          programs: action.payload.data.programs,
          id_quotation: action.payload.data.quote,
          quotation:{
            ...state.quotation,
            card:{}
          },
          programs_fetching: false,
          programs_fetched: true,
          programs_error: false,
          changeEmail: false,
          programs_error_message: ''
        }
      }else{
        if(action.payload.data.changeEmail){
          return {
            ...state,
            programs: [],
            id_quotation: null,
            programs_fetching: false,
            programs_fetched: false,
            programs_error: false,
            changeEmail: true,
          }
        }else{
          let contact = action.payload.data.contact;
          return {
            ...state,
            programs: [],
            id_quotation: null,
            changeEmail: false,
            programs_fetching: false,
            programs_fetched: false,
            programs_error: true,
            programs_error_message: action.payload.data.message,
            update_contact_quotation: !!contact?    contact.phone?"phone":"email" : false
          }
        }
        
      }
    case ActionsTypes.API_GET_PROGRAMS_VIAJE + ActionsTypes.FETCHING:
      return {
        ...state,
        get_programs_fetching: true,
        get_programs_fetched: false,
        get_programs_error: false,
        programs_id: [],
      }
    
    case ActionsTypes.API_GET_PROGRAMS_VIAJE + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.success){
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: true,
          get_programs_error: false,
          programs_id: action.payload.data.programs,
        }
      }else{
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: false,
          get_programs_error: true,
          programs_id: [],
        }
      }
    case ActionsTypes.SET_PROGRAM_VALUE_VIAJE:
      return {
        ...state,
        programsValues:{
          ...action.payload
        }
      }

    case ActionsTypes.SET_PROGRAM_VALUE_VIAJE_USUARIO:
      return {
        ...state,
        programsValuesUsuario:{
          ...action.payload
        }
      }
      
    case ActionsTypes.API_POST_COTIZACIONES + ActionsTypes.ERROR:
      return {
        ...state,
        programs_fetching: false,
        programs_fetched: false,
        changeEmail: false,
        programs_error: true,
      }
    case ActionsTypes.DELETE_COVERAGE:
      let quotation = state.quotation;
      quotation.insureds[action.payload].specialCoverage = null;
      return {
        ...state,
        quotation
      }
    case ActionsTypes.RESET_QUOTATION:
      return {
        ...state,
        id_quotation: action.payload,
      }
    case ActionsTypes.RESET_QUOTATION_EMIT:
      return {
        ...state,
        programs_fetching: null,
        programs_fetched: null,
        programs_error: null,
        programs_error_message: '',
      }
    case ActionsTypes.SELECT_COTIZACION:
      return {
        ...state,
        quotation: {...action.payload},
      }
    case ActionsTypes.API_POST_GET_COTIZACIONES + ActionsTypes.FETCHING:
      return {
        ...state,
        programs_fetching: true,
        programs_fetched: false
      }
    case ActionsTypes.API_POST_GET_COTIZACIONES + ActionsTypes.FETCHED:
      return {
        ...state,
        programs: action.payload.data.programs,
        programs_fetching: false,
        programs_fetched: true
      }
    case ActionsTypes.API_DOWNLOAD_VENTAS + ActionsTypes.FETCHED:
      const xls = action.payload.data
      return {
        ...state,
        xls
      }
    case ActionsTypes.RESET_XLS:
      return {
        ...state,
        xls: null
      }
    case ActionsTypes.REVERSE_PROGRAMS:
      return {
        ...state,
        programs: action.payload,
      };
    case ActionsTypes.SELECT_PROGRAM:
      return {
        ...state,
        program_selected: action.payload
      }
    case ActionsTypes.SEARCH_COTIZACION:
      return {
        ...state,
        search_param_quotes: action.payload.search,
        page_param_quotes:  action.payload.page,
        search_param_quotes_loading: true,
      }
    case ActionsTypes.SEARCH_SALE:
      return {
        ...state,
        search_param_sale: action.payload.search,
        page_param_sale:  action.payload.page,
        search_param_sale_loading: true,
      }
    case ActionsTypes.RESET_PAGINATION:
      return {
        ...state,
        search_param_sale: "",
        search_param_quotes: "",
        page_param_sale:  0,
        page_param_quotes: 0
      }
    case ActionsTypes.FILTER_COTIZACION:
      return {
        ...state,
        cotizaciones_filter: action.payload
      }
    case ActionsTypes.RESET_COTIZACION:
      return {
        ...state,
        quotation: {
          origin: '',//"COL",
          destinations: [
            {
              start: '',
              end: '',
              iso3: ''
            }
          ],
          travelType: 1,//2
          numberDays: null, //30
          start: '',// "15/05/2020"
          insureds: [
            {
              gender: '',//"male",
              specialCoverage: null,
              birthDate: '',//"09/05/1995",
              nationality: '',//"VEN"
              months: null,
              firstName: '',
              lastName: '',
              document: '',
              isPrimary: true
            }
          ],
          filters: {
            insurers: [],
            medicalAssistance: {
              min: 0,
              max: 250000
            },
            transport: {
              min: 0,
              max: 9999999
            },
            cancel: {
              min: 0,
              max: 9999999
            },
            tripInterruption: {
              min: 0,
              max: 9999999
            },
            lostLuggage: {
              min: 0,
              max: 9999999
            },
            deductible: {
              min: 0,
              max: 9999999
            },
            pregnancy: false,
            preexistingDiseases: false,
            extremeSports: false
          },
          contact: {
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
          },
          emergency_contact: null,
          name: "",
          email: "",
          phone: "",
          message: "",
          card: {
            month: '',
            year: '',
            name: '',
            cvc: '',
            number: ''
          },
          optionId: "",
          invoiceTo: {
            country: "",
            documentType: "",
            firstName: "",
            lastName: "",
            document: "",
            phone: "",
            email: "",
            address: "",
            address2: "",
            zip: "",
            state: "",
            city: "",
            birthDate: ""
          },
        },
        programs_fetching:false,
        programs_fetched:false,
        programs_error:false,
        programs_error_message: '',
      }
    case ActionsTypes.UPDATE_FILTER_SEARCH_BOX:
      return {
        ...state,
        filter_search:action.payload
      }
    case ActionsTypes.RESET_PURCHASE:
      return {
        ...state,
        result_purchase: null,
        fetched_purchase: null,
        fetching_purchase: null,
        error_purchase: null,
      }
    case ActionsTypes.SET_ERROR_COTIZADOR_VIAJE:
      let error = state.error;
      if(!error){
        error = initial_state.error;
      }
      if(action.payload.label == "destination" || action.payload.label == "contact"){
        return {
          ...state,
          error:{
            ...error,
            cotizador:{
              ...error.cotizador,
              [action.payload.label]:{
                ...error.cotizador[action.payload.label],
                [action.payload.label2]:action.payload.value
              }
            }
          }
        }
      }else{
        return {
          ...state,
          error:{
            ...error,
            cotizador:{
              ...error.cotizador,
              [action.payload.label]:action.payload.value
            }
          }
        }
      }
    default:
      return state;
  }
};
